import { useState, useEffect } from "react";
import User from "../interface/User";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Projects from "../components/Projects";

const Dashboard = () => {
  const [userInfo, setUserInfo] = useState<User>();

  const history = useNavigate();

  useEffect(() => {
    const token = Cookies.get("access_token")
      ? JSON.parse(Cookies.get("access_token") || "")
      : undefined;
    setUserInfo(token);
    if (!token) {
      history("/admin/auth");
    }
  }, [history]);
  return (
    <>
      <Header />
      <h3 className="text-center mt-10 font-bold text-2xl text-teal-500">{`Welcome back ${userInfo?.name}`}</h3>
      <Projects />
      <Footer />
    </>
  );
};

export default Dashboard;
