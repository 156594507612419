import Header from "../components/Header";
import Properties from "../components/Properties";
import Coverflow from "../components/Coverflow";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";

const Home = () => {
  const { categoryId } = useParams();

  return (
    <>
      <Header />
      <Coverflow categoryId={categoryId} />
      <Properties categoryId={categoryId} />
      <Footer />
    </>
  );
};

export default Home;
