import React from "react";
import { useState, useEffect } from "react";
import User from "../interface/User";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import { ApiURL } from "../utils/ApiURL";
import { handleError } from "../utils/handleError";
import { Category } from "../interface/Category";

interface newProjectModalProps {
  isOpen?: boolean;
  setIsopen?: boolean | any;
}

const NewProjectModal = ({ isOpen, setIsopen }: newProjectModalProps) => {
  const options = [
    {
      type: "Rehab",
      value: "Rehab",
    },
    {
      type: "Rental",
      value: "Rental",
    },
    {
      type: "Multifamily",
      value: "Multifamily",
    },
  ];

  const [userInfo, setUserInfo] = useState<User>();
  const [title, setTitle] = useState("");
  const [categories, setCategories] = useState<Category[]>([]);
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [type, setType] = useState(options[0].value);
  const [category, setCategory] = useState();
  const [partnerUrl, setPartnerUrl] = useState("");
  const [listingUrl, setListingUrl] = useState("");

  const history = useNavigate();

  const changeHandler = (e: any) => {
    const file = e.target.files[0];
    setImage(file);
  };

  useEffect(() => {
    const token = Cookies.get("access_token")
      ? JSON.parse(Cookies.get("access_token") || "")
      : undefined;
    setUserInfo(token);
    if (!token) {
      history("/admin/auth");
    }
  }, [history]);

  const categoriesList = async () => {
    try {
      const { data } = await axios.get(`${ApiURL}/api/categories`);

      if (data) {
        setCategories(data?.categories);
        setCategory(data?.categories[0]?._id);
      }
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  useEffect(() => {
    categoriesList();
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      if (!image) {
        return toast.error("Image is required", { theme: "colored" });
      }
      const { data } = await axios.post(
        `${ApiURL}/api/projects`,
        {
          title,
          description,
          type,
          image,
          category,
          listingUrl,
          partnerUrl,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userInfo?.access_token}`,
          },
        }
      );
      toast.success(data?.message, { theme: "colored" });
      history("/dashboard");
      setIsopen(false);
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };
  return (
    <>
      <div
        id="new-project-modal"
        tabIndex={-1}
        aria-hidden="true"
        className={`${
          isOpen
            ? "flex backdrop-blur-sm bg-white/30 backdrop-brightness-50 overflow-y-auto overflow-x-hidden fixed px-auto top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
            : "hidden overflow-y-auto overflow-x-hidden fixed px-auto top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
        }`}
      >
        <div className="relative p-4 w-full max-w-2xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-white">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-bold text-gray-900 dark:text-gray-900">
                Add New Project
              </h3>
              <button
                type="button"
                onClick={() => setIsopen(!isOpen)}
                className="text-gray-400 bg-transparent hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:text-teal-600"
                data-modal-hide="default-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4">
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
                  >
                    Title
                  </label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Luxury villa"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="description"
                    className=" mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
                  >
                    description
                  </label>
                  <textarea
                    name="description"
                    rows={3}
                    id="description"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Short description about property"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <label
                  htmlFor="type"
                  className="block text-sm font-medium text-gray-900 dark:text-gray-800"
                >
                  Select property type
                </label>
                <select
                  id="type"
                  value={type}
                  name="type"
                  onChange={(e: any) => setType(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  {options.map((option) => (
                    <option id="type" key={option.value} value={option.value}>
                      {option.type}
                    </option>
                  ))}
                </select>

                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-900 dark:text-gray-800"
                >
                  Category
                </label>
                <select
                  id="category"
                  value={category}
                  name="category"
                  onChange={(e: any) => setCategory(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  {categories &&
                    categories.map((category) => (
                      <option
                        id="category"
                        key={category._id}
                        value={category._id}
                      >
                        {category.name}
                      </option>
                    ))}
                </select>

                <div>
                  <label
                    htmlFor="image"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
                  >
                    Image
                  </label>
                  <input
                    type="file"
                    name="image"
                    id="image"
                    onChange={changeHandler}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div>
                  <label
                    htmlFor="listingUrl"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
                  >
                    Listing URL
                  </label>
                  <input
                    type="text"
                    name="listingUrl"
                    id="listingUrl"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Listing URL"
                    value={listingUrl}
                    onChange={(e) => setListingUrl(e.target.value)}
                  />
                </div>

                <div>
                  <label
                    htmlFor="partnerUrl"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
                  >
                    Partner URL
                  </label>
                  <input
                    type="text"
                    name="partnerUrl"
                    id="partnerUrl"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Partner URL"
                    value={partnerUrl}
                    onChange={(e) => setPartnerUrl(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                onClick={(e: any) => handleSubmit(e)}
                data-modal-hide="default-modal"
                type="button"
                className="text-white bg-teal-700 hover:bg-teal-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-teal-600 dark:hover:bg-teal-700 dark:focus:ring-teal-800"
              >
                Add project
              </button>
              <button
                onClick={() => setIsopen(!isOpen)}
                data-modal-hide="default-modal"
                type="button"
                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-white dark:text-dark dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewProjectModal;
