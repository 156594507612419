import React, { useEffect, useState } from "react";
import { Project } from "../interface/Project";
import Cookies from "js-cookie";
import User from "../interface/User";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { ApiURL } from "../utils/ApiURL";
import { handleError } from "../utils/handleError";

const useProject = () => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState<Boolean>(false);

  const history = useNavigate()

  const getProjects = async (token: string) => {
    try {
      const { data } = await axios.get(`${ApiURL}/api/projects`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      return data?.projects;
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  const getProjectById = async (id: string) => {
    try {
      const { data } = await axios.get(`${ApiURL}/api/projects/${id}`, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      });
      setLoading(false);
      return data?.project;
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  const addProject = async (
    e: React.FormEvent<HTMLFormElement>,
    {
      title,
      description,
      type,
      image,
      category,
      listingUrl,
      partnerUrl,
    }: Project,
    token: string
  ) => {
    try {
      e.preventDefault();
      if (!image) {
        return toast.error("Image is required", { theme: "colored" });
      }
      const { data } = await axios.post(
        `${ApiURL}/api/projects`,
        {
          title,
          description,
          type,
          image,
          category,
          listingUrl,
          partnerUrl,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(data?.message, { theme: "colored" });
      history("/dashboard");
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  const editProject = async (
    id: string,
    { title, description, type, category, listingUrl, partnerUrl }: Project,
    token: string
  ) => {
    try {
      const { data } = await axios.patch(
        `${ApiURL}/api/projects/${id}`,
        {
          title,
          description,
          type,
          category,
          listingUrl,
          partnerUrl,
        },
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(data?.message, { theme: "colored" });
      history("/dashboard");
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  const deleteProject = async (id: string, token: string) => {
    try {
      if (token) {
        if (window.confirm("Are you sure want delete this project?")) {
          const { data } = await axios.delete(`${ApiURL}/api/projects/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          setSuccess(true);
          toast.success(data?.message, { theme: "colored" });
        }
      }
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  const getProjectsByCategory = async (categoryId: string) => {
    try {
      const { data } = await axios.get(
        `${ApiURL}/api/projects/category/${categoryId}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setLoading(false);
      return data?.projects;
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  const updateProjectImage = async(id: string, image: any, token: string) => {
    try {
      const { data } = await axios.patch(
        `${ApiURL}/api/projects/image/${id}`,
        {
          image,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(data?.message, { theme: "colored" });
      history("/dashboard");
      setSuccess(true);
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  }

  return {
    loading,
    getProjects,
    getProjectById,
    addProject,
    editProject,
    deleteProject,
    getProjectsByCategory,
    updateProjectImage
  };
};

export default useProject;
