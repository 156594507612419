import { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { toast } from "react-toastify";
import { handleError } from "../utils/handleError";
import { Link, useParams } from "react-router-dom";
import { Project } from "../interface/Project";
import useProject from "../hooks/useProject";

const SingleProject = () => {
  const [project, setProject] = useState<Project>();

  const { loading, getProjectById } = useProject();

  const { id } = useParams();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const data = await getProjectById(id as string);

        setProject(data);
      } catch (error) {
        toast.error(handleError(error), { theme: "colored" });
      }
    };

    fetchProject();
  }, [getProjectById, id]);

  return (
    <>
      <Header />
      {loading ? (
        <p className="text-center">Loading...</p>
      ) : (
        <div
          className="max-w-sm bg-white border m-auto mt-20 border-gray-200 rounded-lg shadow dark:bg-white dark:border-gray-300"
          style={{ width: "80%", maxWidth: "100%" }}
        >
          <img
            className="rounded-t-lg"
            loading="lazy"
            src={
              project?.image
                ? project?.image
                : "/assets/images/luxury-real-estate.jpg"
            }
            alt={project?.title}
            style={{ width: "100%", maxWidth: "100%" }}
          />
          <div className="p-5">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-teal-700">
              {project?.title}
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 whitespace-pre-wrap">
              {project?.description}
            </p>
            {project?.listingUrl && (
              <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                Listing URL:{" "}
                <Link className="text-teal-600" to={`${project?.listingUrl}`}>
                  {project?.listingUrl}
                </Link>
              </p>
            )}

            {project?.partnerUrl && (
              <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                Partner URL:{" "}
                <Link className="text-teal-600" to={`${project?.partnerUrl}`}>
                  {project?.partnerUrl}
                </Link>
              </p>
            )}

            <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
              {project?.type}
            </span>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default SingleProject;
