import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { handleError } from "../utils/handleError";
import { Category } from "../interface/Category";
import Header from "../components/Header";
import Footer from "../components/Footer";
import useProject from "../hooks/useProject";
import useCategory from "../hooks/useCategory";
import Input from "../components/Input";
import Textarea from "../components/Textarea";
import Cookies from "js-cookie";
import User from "../interface/User";

const options = [
  {
    type: "Rehab",
    value: "Rehab",
  },
  {
    type: "Rental",
    value: "Rental",
  },
  {
    type: "Multifamily",
    value: "Multifamily",
  },
];

const EditProject = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [categories, setCategories] = useState<Category[]>([]);
  const [category, setCategory] = useState();
  const [type, setType] = useState();
  const [partnerUrl, setPartnerUrl] = useState("");
  const [listingUrl, setListingUrl] = useState("");
  const [userInfo, setUserInfo] = useState<User>();

  const history = useNavigate();

  const { getProjectById, editProject } = useProject();
  const { getCategories } = useCategory();

  const { id } = useParams();

  useEffect(() => {
    const token = Cookies.get("access_token")
      ? JSON.parse(Cookies.get("access_token") || "")
      : undefined;
    setUserInfo(token);
    if (!token) {
      history("/admin/auth");
    }
  }, [history]);

  const categoriesList = async () => {
    try {
      const data = await getCategories();

      if (data) {
        setCategories(data);
        setCategory(data?.[0]?._id);
      }
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  const fetchProject = async () => {
    try {
      const data = await getProjectById(id as string);
      if (data) {
        setTitle(data?.title);
        setDescription(data?.description);
        setCategory(data?.category?._id);
        setType(data?.type);
      }
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  useEffect(() => {
    fetchProject();
    categoriesList();
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await editProject(
        id as string,
        {
          title,
          description,
          type,
          category,
          listingUrl,
          partnerUrl,
        },
        userInfo?.access_token as string
      );
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };
  return (
    <>
      <Header />
      <section className="">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <Link
            to="/dashboard"
            className="flex items-center mb-6 text-2xl font-semibold from-teal-600 via-teal-600 to-teal-400 bg-gradient-to-r bg-clip-text text-transparent"
          >
            <img src="/images/logo.png" alt="logo" width={250} />
          </Link>
          <div className="w-full bg-gray-50 rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-white dark:border-white">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-gray-800">
                Edit project
              </h1>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <Input
                  label="Title"
                  type="text"
                  name="title"
                  placeholder="Luxury villa"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />

                <label
                  htmlFor="type"
                  className="block text-sm font-medium text-gray-900 dark:text-gray-800"
                >
                  Type
                </label>
                <select
                  id="type"
                  value={type}
                  name="type"
                  onChange={(e: any) => setType(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  {options.map((option) => (
                    <option id="type" key={option.value} value={option.value}>
                      {option.type}
                    </option>
                  ))}
                </select>
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-900 dark:text-gray-800"
                >
                  Category
                </label>
                <select
                  id="category"
                  value={category}
                  name="category"
                  onChange={(e: any) => setCategory(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  {categories &&
                    categories.map((category) => (
                      <option
                        id="category"
                        key={category._id}
                        value={category._id}
                      >
                        {category.name}
                      </option>
                    ))}
                </select>
                <Textarea
                  label="Description"
                  name="description"
                  rows={3}
                  placeholder="Short description about property"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />

                <Input
                  label="Listing URL"
                  type="text"
                  name="listingurl"
                  placeholder="Listing URL"
                  value={listingUrl}
                  onChange={(e) => setListingUrl(e.target.value)}
                />

                <Input
                  label="Partner URL"
                  type="text"
                  name="partnerurl"
                  placeholder="Partner URL"
                  value={partnerUrl}
                  onChange={(e) => setPartnerUrl(e.target.value)}
                />

                <button
                  type="submit"
                  className="w-full text-white bg-teal-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Edit Project
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default EditProject;
