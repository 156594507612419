import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { handleError } from "../utils/handleError";
import useCategory from "../hooks/useCategory";
import Input from "../components/Input";
import Cookies from "js-cookie";
import User from "../interface/User";

const UpdateCategory = () => {
  const [name, setName] = useState<any>("");
  const [userInfo, setUserInfo] = useState<User>();

  const { getCategoryById, editCategory } = useCategory();

  const { id } = useParams();

  const history = useNavigate();

  useEffect(() => {
    const token = Cookies.get("access_token")
      ? JSON.parse(Cookies.get("access_token") || "")
      : undefined;
    setUserInfo(token);
    if (!token) {
      history("/admin/auth");
    }
  }, [history]);

  const fetchCategory = async () => {
    try {
      const data = await getCategoryById(id as string);
      if (data) {
        setName(data);
      }
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await editCategory(
        id as string,
        name as any,
        userInfo?.access_token as string
      );
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };
  return (
    <>
      <section className="bg-gray-50 p-10">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <Link
            to="/dashboard"
            className="flex items-center mb-6 text-2xl font-semibold from-teal-600 via-teal-600 to-teal-400 bg-gradient-to-r bg-clip-text text-transparent"
          >
            <img src="/images/logo.png" alt="logo" width={250} />
          </Link>
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-white dark:border-white">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-gray-800">
                Update Category
              </h1>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <Input
                  label="Category name"
                  type="text"
                  name="name"
                  placeholder="Single Family"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />

                <button
                  type="submit"
                  className="w-full text-white bg-teal-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Add Category
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpdateCategory;
