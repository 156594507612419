import React from "react";

const ComingSoon = () => {
  return (
    <>
      <div className="min-h-screen bg-white text-black flex flex-col items-center justify-center">
        <h1 className="text-5xl text-black font-bold mb-8 animate-pulse">
          Coming Soon
        </h1>
        <p className="text-black text-lg mb-8">
          We're working hard to bring you something amazing. Stay tuned!
        </p>
      </div>
    </>
  );
};

export default ComingSoon;
