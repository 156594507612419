import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import User from "../interface/User";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { handleError } from "../utils/handleError";
import { ApiURL } from "../utils/ApiURL";
import axios from "axios";

function Header() {
  const [userInfo, setUserInfo] = useState<User>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const history = useNavigate();

  useEffect(() => {
    const token = Cookies.get("access_token")
      ? JSON.parse(Cookies.get("access_token") || "")
      : undefined;
    setUserInfo(token);
  }, []);

  const logout = async () => {
    try {
      await axios.post(`${ApiURL}/api/auth/logout`);
      Cookies.remove("access_token");
      history("/admin/auth");
      toast.success("Logged out successfully", { theme: "colored" });
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };
  return (
    <>
      <nav className="bg-white border-gray-200 text-black mt-3 sticky top-0 z-50">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Link
            to="/select-category"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img src="/images/logo.png" className="h-11" alt="Flowbite Logo" />
            {/* <span className="self-left from-teal-600 via-teal-600 to-teal-400 bg-gradient-to-r bg-clip-text text-transparent text-3xl font-semibold whitespace-nowrap">
              1822 Ventures
            </span> */}
          </Link>
          <div className="flex md:order-2">
            {/* <button
              type="button"
              data-collapse-toggle="navbar-search"
              aria-controls="navbar-search"
              aria-expanded="false"
              className="md:hidden text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5 me-1"
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
              <span className="sr-only">Search</span>
            </button> */}
            {/* <div className="relative hidden md:block">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span className="sr-only">Search icon</span>
              </div>
              <input
                type="text"
                id="search-navbar"
                className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-white dark:border-gray-200 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search..."
              />
            </div> */}
            <button
              data-collapse-toggle="navbar-search"
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-white dark:focus:ring-gray-600"
              aria-controls="navbar-search"
              aria-expanded={isOpen ? "true" : "false"}
            >
              <span className="sr-only w-full md:block md:w-auto">
                Open main menu
              </span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
          <div
            className={
              isOpen
                ? "w-full md:block md:w-auto"
                : "w-full md:block md:w-auto hidden"
            }
          >
            <div
              className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
              id="navbar-search"
            >
              <div className="relative mt-3 md:hidden">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="false"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="search-navbar"
                  className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search..."
                />
              </div>
            </div>
            <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white ">
              {userInfo ? (
                <>
                  <li>
                    <Link
                      to="/select-category"
                      className="block py-2 px-3 md:hover:text-teal-600 md:p-0 md:dark:hover:text-teal-400 text-gray-800 bg-gray-200 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-gray-500"
                      aria-current="page"
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/dashboard"
                      className="block py-2 px-3 md:hover:text-teal-600 md:p-0 md:dark:hover:text-teal-400 text-gray-800 bg-gray-200 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-gray-500"
                      aria-current="page"
                    >
                      Dashboard
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/users"
                      className="block py-2 px-3 md:hover:text-teal-600 md:p-0 md:dark:hover:text-teal-400 text-gray-800 bg-gray-200 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-gray-500"
                      aria-current="page"
                    >
                      Users
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/categories"
                      className="block py-2 px-3 md:hover:text-teal-600 md:p-0 md:dark:hover:text-teal-400 text-gray-800 bg-gray-200 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-gray-500"
                      aria-current="page"
                    >
                      Categories
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/users/add"
                      className="block py-2 px-3 md:hover:text-teal-600 md:p-0 md:dark:hover:text-teal-400 text-gray-800 bg-gray-200 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-gray-500"
                      aria-current="page"
                    >
                      Add User
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about"
                      className="block py-2 px-3 md:hover:text-teal-600 md:p-0 md:dark:hover:text-teal-400 text-gray-800 bg-gray-200 rounded md:bg-transparent md:text-gray-500 md:p-0 md:dark:text-gray-500"
                      aria-current="page"
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={logout}
                      className="block py-2 px-3 md:hover:text-teal-600 md:p-0 md:dark:hover:text-teal-400 text-gray-800 bg-gray-200 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-gray-500"
                      aria-current="page"
                    >
                      Logout
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link
                      to="/select-category"
                      className="block py-2 px-3 md:hover:text-teal-600 md:p-0 md:dark:hover:text-teal-400 text-gray-800 bg-gray-200 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-gray-500"
                      aria-current="page"
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about"
                      className="block py-2 px-3 md:hover:text-teal-600 md:p-0 md:dark:hover:text-teal-400 text-gray-800 bg-gray-200 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-gray-500"
                      aria-current="page"
                    >
                      About
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
