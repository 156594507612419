import axios from "axios";
import { ApiURL } from "../utils/ApiURL";
import { toast } from "react-toastify";
import { handleError } from "../utils/handleError";
import Cookies from "js-cookie";
import User from "../interface/User";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Category } from "../interface/Category";

const useCategory = () => {
  const [loading, setLoading] = useState(true);

  const history = useNavigate();

  const getCategories = async () => {
    try {
      const { data } = await axios.get(`${ApiURL}/api/categories`);
      setLoading(false);
      return data?.categories;
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  const getCategoryById = async (id: string) => {
    try {
      const { data } = await axios.get(`${ApiURL}/api/categories/${id}`);
      setLoading(false);
      return data?.category?.name;
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  const addCategory = async (name: string, token: string) => {
    try {
      const { data } = await axios.post(
        `${ApiURL}/api/categories`,
        {
          name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(data?.message, { theme: "colored" });
      history("/categories");
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  const editCategory = async (categoryId: string, name: Category, token: string) => {
    try {
      const { data } = await axios.patch(
        `${ApiURL}/api/categories/${categoryId}`,
        {
          name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(data?.message, { theme: "colored" });
      history("/categories");
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  const deleteCategory = async (id: string, token: string) => {
    try {
      const { data } = await axios.delete(`${ApiURL}/api/categories/${id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(data?.message, { theme: "colored" });
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  return {
    loading,
    getCategories,
    getCategoryById,
    addCategory,
    editCategory,
    deleteCategory,
  };
};
export default useCategory;
