import React, { useEffect, useState } from "react";
import User from "../interface/User";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ApiURL } from "../utils/ApiURL";
import { toast } from "react-toastify";
import { handleError } from "../utils/handleError";
import Input from "../components/Input";
import Textarea from "../components/Textarea";

const UpdateAbout = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [userInfo, setUserInfo] = useState<User>();
  const [success, setSuccess] = useState<boolean>(false);

  const history = useNavigate();

  useEffect(() => {
    const token = Cookies.get("access_token")
      ? JSON.parse(Cookies.get("access_token") || "")
      : undefined;
    setUserInfo(token);
    if (!token) {
      history("/admin/auth");
    }
  }, [history, success]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`${ApiURL}/api/about-us`);
        setTitle(data?.content?.title);
        setDescription(data?.content?.description);
      } catch (error) {
        toast.error(handleError(error), { theme: "colored" });
      }
    };

    fetchData();
  }, [success]);

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    try {
      e.preventDefault();
      const { data } = await axios.patch(
        `${ApiURL}/api/about-us`,
        {
          title,
          description,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo?.access_token}`,
          },
        }
      );
      toast.success(data?.message, { theme: "colored" });
      setSuccess(true);
      history("/about");
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };
  return (
    <>
      <section className="bg-gray-50 ">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <Link
            to="/dashboard"
            className="flex items-center mb-6 text-2xl font-semibold from-teal-600 via-teal-600 to-teal-400 bg-gradient-to-r bg-clip-text text-transparent"
          >
            <img src="/images/logo.png" alt="logo" width={250} />
          </Link>
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-white dark:border-white">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-gray-800">
                Update about section
              </h1>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <Input
                  label="Title"
                  type="text"
                  name="title"
                  placeholder="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <Textarea
                  label="Description"
                  name="description"
                  rows={6}
                  placeholder="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <button
                  type="submit"
                  className="w-full text-white bg-teal-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpdateAbout;
