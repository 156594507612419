import { useEffect, useState } from "react";
import { Category } from "../interface/Category";
import { handleError } from "../utils/handleError";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import useCategory from "../hooks/useCategory";

const SelectCategory = () => {
  const [categories, setCategories] = useState<Category[]>([]);

  const { loading, getCategories } = useCategory();

  const categoriesList = async () => {
    try {
      const data = await getCategories();

      if (data) {
        setCategories(data);
      }
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  useEffect(() => {
    categoriesList();
  }, []);

  return (
    <>
      <Header />
      <h3 className="m-10 text-center p-3 text-3xl font-extrabold">Projects</h3>
      <div className="flex flex-wrap justify-center ">
        {loading ? (
          <span>Loading...</span>
        ) : (
          categories &&
          categories.map((category: Category) => (
            <div
              key={category?._id}
              className="card m-5 text-center font-extrabold text-3xl"
            >
              <Link to={`/home/${category.name}/${category?._id}`}>
                <h3 className="mt-10 py-14">{category?.name}</h3>
              </Link>
            </div>
          ))
        )}
      </div>
      <Footer />
    </>
  );
};

export default SelectCategory;
