import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import User from "../interface/User";
import { toast } from "react-toastify";
import { handleError } from "../utils/handleError";
import Input from "../components/Input";
import useUser from "../hooks/useUser";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { login, userInfo } = useUser();

  const history = useNavigate();

  useEffect(() => {
    if (userInfo) {
      history("/dashboard");
    }
  }, [history]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      await login({ email, password } as User);
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  return (
    <>
      <section className="bg-gray-50 ">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <Link
            to="/"
            className="flex items-center mb-6 text-2xl font-semibold from-teal-600 via-teal-600 to-teal-400 bg-gradient-to-r bg-clip-text text-transparent"
          >
            <img src="/images/logo.png" alt="1822_Ventures" width={250} />
          </Link>
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-white dark:border-white">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-gray-800">
                Sign in to Admin Panel
              </h1>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <Input
                  label="Email"
                  type="email"
                  name="email"
                  placeholder="name@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                  label="Password"
                  type="password"
                  name="password"
                  placeholder="**********"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="flex items-center justify-between">
                  <Link
                    to="/admin/auth/forgot-password"
                    className="text-sm font-medium text-primary-600 hover:underline dark:text-teal-500"
                  >
                    Forgot password?
                  </Link>
                </div>
                <button
                  type="submit"
                  className="w-full text-white bg-teal-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Sign in
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
