import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCoverflow,
} from "swiper/modules";
import SwiperCore from "swiper";
import { Link } from "react-router-dom";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useEffect, useState } from "react";
import { handleError } from "../utils/handleError";
import { toast } from "react-toastify";
import { Project } from "../interface/Project";
import ComingSoon from "./ComingSoon";
import useProject from "../hooks/useProject";

SwiperCore.use([Navigation, Pagination, EffectCoverflow]);

const Coverflow = ({ categoryId }: any) => {
  const [projects, setProjects] = useState<Project[]>([]);

  const { getProjectsByCategory } = useProject();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const data = await getProjectsByCategory(categoryId);
        setProjects(data);
      } catch (error) {
        toast.error(handleError(error), { theme: "colored" });
      }
    };

    fetchProjects();
  }, [categoryId]);

  return (
    <>
      {projects?.length === 0 ? (
        <ComingSoon />
      ) : (
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          navigation
          pagination={{ clickable: true }}
          className="mt-5"
          effect="coverflow"
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          slidesPerView={3}
          style={{
            width: "1200px",
            maxWidth: "100%",
            minWidth: "750px",
            marginTop: "6%",
            marginBottom: "6%",
          }}
        >
          {projects?.map((project: Project) => (
            <SwiperSlide>
              <div
                key={project?._id}
                className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
              >
                <Link to={`/project/${project?._id}`}>
                  <img
                    className="rounded-t-lg"
                    src={project?.image}
                    alt={project?.title}
                    style={{ width: "100%", maxWidth: "100%" }}
                  />
                </Link>

                <div className="p-5">
                  <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                    {project?.type}
                  </span>
                  <Link to={`/project/${project?._id}`}>
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                      {project?.title}
                    </h5>
                  </Link>

                  <Link
                    to={`/project/${project?._id}`}
                    className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-teal-600 dark:hover:bg-teal-800 dark:focus:ring-blue-800"
                  >
                    See more
                    <svg
                      className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
};

export default Coverflow;
