import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { handleError } from "../utils/handleError";
import axios from "axios";
import { ApiURL } from "../utils/ApiURL";
import User from "../interface/User";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import Input from "../components/Input";

const UpdateAboutImage = () => {
  const [image, setImage] = useState(null);
  const [userInfo, setUserInfo] = useState<User>();
  const [success, setSuccess] = useState<boolean>(false);

  const changeHandler = (e: any) => {
    const file = e.target.files[0];
    setImage(file);
  };

  useEffect(() => {
    const token = Cookies.get("access_token")
      ? JSON.parse(Cookies.get("access_token") || "")
      : undefined;
    setUserInfo(token);
  }, [success]);

  const history = useNavigate();

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    try {
      e.preventDefault();
      const { data } = await axios.patch(
        `${ApiURL}/api/about-us/image/`,
        {
          image,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userInfo?.access_token}`,
          },
        }
      );
      toast.success(data?.message, { theme: "colored" });
      history("/about");
      setSuccess(true);
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  return (
    <>
      <section className="bg-gray-50 ">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <Link
            to="/dashboard"
            className="flex items-center mb-6 text-2xl font-semibold from-teal-600 via-teal-600 to-teal-400 bg-gradient-to-r bg-clip-text text-transparent"
          >
            <img src="/images/logo.png" alt="logo" width={250} />
          </Link>
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-white dark:border-white">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-gray-800">
                Edit project
              </h1>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <Input
                  label="Image"
                  type="file"
                  name="image"
                  onChange={changeHandler}
                />

                <button
                  type="submit"
                  className="w-full text-white bg-teal-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpdateAboutImage;
