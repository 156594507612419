import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Project } from "../interface/Project";
import { toast } from "react-toastify";
import { handleError } from "../utils/handleError";
import NewProjectModal from "./NewProjectModal";
import useProject from "../hooks/useProject";
import Cookies from "js-cookie";
import User from "../interface/User";

const Projects = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [userInfo, setUserInfo] = useState<User>();

  const { loading, getProjects, deleteProject } = useProject();

  const history = useNavigate();

  useEffect(() => {
    const token = Cookies.get("access_token")
      ? JSON.parse(Cookies.get("access_token") || "")
      : undefined;
    setUserInfo(token);
    if (!token) {
      history("/admin/auth");
    }
  }, [history]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        if (userInfo?.access_token) {
          const data = await getProjects(userInfo?.access_token);
          if (data) {
            setProjects(data);
          }
        }
      } catch (error) {
        toast.error(handleError(error), { theme: "colored" });
      }
    };

    fetchProjects();
  }, [userInfo?.access_token]);

  return (
    <>
      <NewProjectModal isOpen={isOpen} setIsopen={setIsOpen} />

      <div
        className="relative overflow-x-auto mt-20 m-auto shadow-md sm:rounded-lg"
        style={{ width: "85%", maxWidth: "100%" }}
      >
        <div className="p-3 m-3">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="underline font-bold text-teal-600 hover:text-teal-800"
            data-modal-target="new-project-modal"
            data-modal-toggle="new-project-modal"
          >
            Add New Project
          </button>
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-800 dark:bg-white dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 font-bold text-teal-500">
                Title
              </th>
              <th scope="col" className="px-6 py-3 font-bold text-teal-500">
                Description
              </th>
              <th scope="col" className="px-6 py-3 font-bold text-teal-500">
                Type
              </th>
              <th scope="col" className="px-6 py-3 font-bold text-teal-500">
                Category
              </th>
              <th scope="col" className="px-6 py-3 font-bold text-teal-500">
                Listing URL
              </th>
              <th scope="col" className="px-6 py-3 font-bold text-teal-500">
                Partner URL
              </th>
              <th scope="col" className="px-6 py-3 font-bold text-teal-500">
                Image
              </th>
              <th scope="col" className="px-6 py-3 font-bold text-teal-500">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <p className="text-center mt-5">Loading...</p>
            ) : projects?.length === 0 ? (
              <p className="text-center m-3">No projects found.</p>
            ) : (
              projects.map((project: Project) => (
                <>
                  <tr
                    className="bg-white border-b dark:bg-white dark:border-gray-700 dark:text-gray-800"
                    key={project?._id}
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-gray-800"
                    >
                      {project?.title}
                    </th>
                    <td className="px-6 py-4 dark:text-gray-800">
                      {project?.description.substring(0, 50) + "..."}
                    </td>
                    <td className="px-6 py-4 dark:text-gray-800">
                      {project?.type}
                    </td>
                    <td className="px-6 py-4 dark:text-gray-800">
                      {project?.category?.name}
                    </td>
                    <td className="px-6 py-4 dark:text-gray-800">
                      <Link
                        to={project?.listingUrl}
                        className={"text-teal-600"}
                      >
                        {project?.listingUrl ? "Listning URL" : "Null"}
                      </Link>
                    </td>
                    <td className="px-6 py-4 dark:text-gray-800">
                      <Link to={project?.partnerUrl} className="text-teal-600">
                        {project?.partnerUrl ? "Partner URL" : "Null"}
                      </Link>
                    </td>
                    <td className="px-6 py-4 dark:text-gray-800">
                      <img
                        className="w-10 h-10 rounded-full"
                        loading="lazy"
                        src={project?.image}
                        alt={project?.title}
                      />
                    </td>
                    <td className="px-6 py-4">
                      <Link
                        to={`/projects/${project?._id}`}
                        className="font-medium mr-3 text-blue-600 dark:text-blue-500 hover:underline"
                        data-modal-target={"edit-project-modal" + project?._id}
                        data-modal-toggle={"edit-project-modal" + project?._id}
                      >
                        Edit
                      </Link>
                      <Link
                        to={`/projects/image/${project?._id}`}
                        className="font-medium mr-3 text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        Edit Image
                      </Link>

                      <Link
                        to=""
                        onClick={() =>
                          deleteProject(
                            project?._id,
                            userInfo?.access_token as string
                          )
                        }
                        className="font-medium ml-3 text-red-600 dark:text-red-500 hover:underline"
                      >
                        Remove
                      </Link>
                    </td>
                  </tr>
                </>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Projects;
