import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer
        className="bg-white rounded-lg shadow mt-auto"
        style={{ marginTop: "8%" }}
      >
        <div className="w-full max-w-screen-xl mx-auto p-4  md:py-8">
          <div className="sm:flex sm:items-center sm:justify-between">
            <Link
              to="/select-category"
              className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
            >
              <img
                src="/images/logo.png"
                className="h-11"
                alt="Flowbite Logo"
              />
              {/* <span className="self-center text-2xl font-semibold whitespace-nowrap from-teal-600 via-teal-600 to-teal-400 bg-gradient-to-r bg-clip-text text-transparent">
                1822 Ventures
              </span> */}
            </Link>
            <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
              <li>
                <Link to="/about" className="hover:underline me-4 md:me-6">
                  About
                </Link>
              </li>
              <li>
                <Link to="/contact" className="hover:underline">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © {new Date().getFullYear()}{" "}
            <Link to="/select-category" className="hover:underline">
              1822 Ventures™
            </Link>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
