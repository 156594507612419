import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import User from "../interface/User";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { handleError } from "../utils/handleError";
import useUser from "../hooks/useUser";

const Users = () => {
  const [userInfo, setUserInfo] = useState<User>();
  const [users, setUsers] = useState<User[]>([]);

  const { loading, success, getUsers, deleteUser } = useUser();

  const history = useNavigate();

  useEffect(() => {
    const token: User = Cookies.get("access_token")
      ? JSON.parse(Cookies.get("access_token") || "")
      : undefined;
    setUserInfo(token);
    if (!token) {
      history("/admin/auth");
    }
  }, [history, success]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (userInfo?.access_token) {
          const data = await getUsers();
          setUsers(data);
        }
      } catch (error) {
        toast.error(handleError(error), { theme: "colored" });
      }
    };

    fetchUsers();
  }, [userInfo?.access_token, success]);

  return (
    <>
      <Header />
      <div
        className="relative overflow-x-auto mt-20 m-auto shadow-md sm:rounded-lg"
        style={{ width: "85%", maxWidth: "100%" }}
      >
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-800 dark:bg-white dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 font-bold text-teal-500">
                Name
              </th>
              <th scope="col" className="px-6 py-3 font-bold text-teal-500">
                Email
              </th>

              <th scope="col" className="px-6 py-3 font-bold text-teal-500">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <p className=" text-teal-500">Loading...</p>
            ) : users?.length === 0 ? (
              <p className="text-center mt-5">No Users found</p>
            ) : (
              users?.map((user: User) => (
                <>
                  <tr className="bg-white border-b dark:bg-white dark:border-gray-700 dark:text-gray-800">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-gray-800"
                    >
                      {user?.name}
                    </th>
                    <td className="px-6 py-4 dark:text-gray-800">
                      {user?.email}
                    </td>
                    <td className="px-6 py-4">
                      <Link
                        to={`/users/${user?._id}`}
                        className="font-medium mr-3 text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        Edit
                      </Link>
                      <Link
                        to={`/users/update-password/${user?._id}`}
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        Update Password
                      </Link>
                      <Link
                        to=""
                        onClick={() => deleteUser(user?._id)}
                        className="font-medium ml-3 text-red-600 dark:text-red-500 hover:underline"
                      >
                        Remove
                      </Link>
                    </td>
                  </tr>
                </>
              ))
            )}
          </tbody>
        </table>
      </div>
      <Footer />
    </>
  );
};

export default Users;
