import React, { ChangeEvent } from "react";

interface TextareaProps {
  label: string;
  name: string;
  rows: number;
  placeholder: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const Textarea: React.FC<TextareaProps> = ({
  label,
  name,
  rows,
  placeholder,
  value,
  onChange,
}) => {
  return (
    <div>
      <label
        htmlFor={name}
        className=" mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
      >
        {label}
      </label>
      <textarea
        name={name}
        rows={rows}
        id="description"
        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-gray-600 whitespace-pre-wrap dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default Textarea;
