import { useEffect, useState } from "react";
import Header from "../components/Header";
import { Category } from "../interface/Category";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { toast } from "react-toastify";
import { handleError } from "../utils/handleError";
import useCategory from "../hooks/useCategory";

const Categories = () => {
  const [categories, setCategories] = useState<Category[]>([]);

  const { loading, getCategories } = useCategory();

  const categoriesList = async () => {
    try {
      const data = await getCategories();

      if (data) {
        setCategories(data);
      }
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  useEffect(() => {
    categoriesList();
  }, []);

  const deleteCategory = async (id: string) => {
    try {
      await deleteCategory(id)
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };
  return (
    <>
      <Header />
      <div
        className="relative overflow-x-auto mt-20 m-auto shadow-md sm:rounded-lg"
        style={{ width: "85%", maxWidth: "100%" }}
      >
        <div className="p-3 m-3">
          <Link
            to="/categories/add"
            className="underline font-bold text-teal-600 hover:text-teal-800"
          >
            Add New Category
          </Link>
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-800 dark:bg-white dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 font-bold text-teal-500">
                Category Name
              </th>
              <th scope="col" className="px-6 py-3 font-bold text-teal-500">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <p className=" text-teal-500">Loading...</p>
            ) : categories?.length === 0 ? (
              <p className="text-center mt-5">No Categories found</p>
            ) : (
              categories &&
              categories?.map((category: Category) => (
                <>
                  <tr className="bg-white border-b dark:bg-white dark:border-gray-700 dark:text-gray-800">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-gray-800"
                    >
                      {category?.name}
                    </th>
                    <td className="px-6 py-4">
                      <Link
                        to={`/categories/${category?._id}`}
                        className="font-medium mr-3 text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        Edit
                      </Link>
                      <Link
                        to=""
                        onClick={() => deleteCategory(category?._id)}
                        className="font-medium ml-3 text-red-600 dark:text-red-500 hover:underline"
                      >
                        Remove
                      </Link>
                    </td>
                  </tr>
                </>
              ))
            )}
          </tbody>
        </table>
      </div>
      <Footer />
    </>
  );
};

export default Categories;
