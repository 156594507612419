import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { handleError } from "../utils/handleError";
import { Category } from "../interface/Category";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Input from "../components/Input";
import Textarea from "../components/Textarea";
import useProject from "../hooks/useProject";
import useCategory from "../hooks/useCategory";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import User from "../interface/User";

const AddProject = () => {
  const options = [
    {
      type: "Rehab",
      value: "Rehab",
    },
    {
      type: "Rental",
      value: "Rental",
    },
    {
      type: "Multifamily",
      value: "Multifamily",
    },
  ];

  const [title, setTitle] = useState("");
  const [categories, setCategories] = useState<Category[]>([]);
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [type, setType] = useState(options[0].value);
  const [category, setCategory] = useState();
  const [partnerUrl, setPartnerUrl] = useState("");
  const [listingUrl, setListingUrl] = useState("");
  const [userInfo, setUserInfo] = useState<User>();

  const { addProject } = useProject();
  const { getCategories } = useCategory();

  const history = useNavigate();

  useEffect(() => {
    const token = Cookies.get("access_token")
      ? JSON.parse(Cookies.get("access_token") || "")
      : undefined;
    setUserInfo(token);
    if (!token) {
      history("/admin/auth");
    }
  }, [history]);

  const changeHandler = (e: any) => {
    const file = e.target.files[0];
    setImage(file);
  };

  useEffect(() => {
    const categoriesList = async () => {
      try {
        const data: any = await getCategories();

        if (data) {
          setCategories(data.categories);
          setCategory(data.categories[0]?._id);
        }
      } catch (error) {
        toast.error(handleError(error), { theme: "colored" });
      }
    };

    categoriesList();
  }, [getCategories]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    await addProject(
      e,
      {
        title,
        description,
        type,
        image,
        category,
        listingUrl,
        partnerUrl,
      },
      userInfo?.access_token as string
    );
  };
  return (
    <>
      <Header />
      <section className="bg-gray-50 p-10">
        <div className="flex flex-col items-center justify-center mt-[5%] px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-white dark:border-white">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-gray-800">
                Create new project
              </h1>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <Input
                  label="Title"
                  type="text"
                  name="title"
                  placeholder="Luxury villa"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <Textarea
                  label="Description"
                  name="description"
                  rows={3}
                  placeholder="Short description about property"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <label
                  htmlFor="type"
                  className="block text-sm font-medium text-gray-900 dark:text-gray-800"
                >
                  Select property type
                </label>
                <select
                  id="type"
                  value={type}
                  name="type"
                  onChange={(e: any) => setType(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  {options.map((option) => (
                    <option id="type" key={option.value} value={option.value}>
                      {option.type}
                    </option>
                  ))}
                </select>

                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-900 dark:text-gray-800"
                >
                  Category
                </label>
                <select
                  id="category"
                  value={category}
                  name="category"
                  onChange={(e: any) => setCategory(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  {categories &&
                    categories.map((category) => (
                      <option
                        id="category"
                        key={category._id}
                        value={category._id}
                      >
                        {category.name}
                      </option>
                    ))}
                </select>
                <Input
                  type="file"
                  label="Image"
                  name="image"
                  onChange={changeHandler}
                />
                <Input
                  label="Image"
                  type="file"
                  name="image"
                  onChange={changeHandler}
                />

                <Input
                  label="Listing URL"
                  type="text"
                  name="listingurl"
                  placeholder="Listing URL"
                  value={listingUrl}
                  onChange={(e) => setListingUrl(e.target.value)}
                />

                <Input
                  label="Partner URL"
                  type="text"
                  name="partnerurl"
                  placeholder="Partner URL"
                  value={partnerUrl}
                  onChange={(e) => setPartnerUrl(e.target.value)}
                />

                <button
                  type="submit"
                  className="w-full text-white bg-teal-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Add Project
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AddProject;
