import { ToastContainer } from "react-toastify";
import NotFoundPage from "./pages/404Page";
import About from "./pages/About";
import Home from "./pages/Home";
import Landing from "./pages/Landing";
import Login from "./pages/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Users from "./components/Users";
import EditUser from "./pages/EditUser";
import AddUser from "./pages/AddUser";
import UpdatePassword from "./pages/UpdatePassword";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import AddProject from "./pages/AddProject";
import EditProject from "./pages/EditProject";
import UpdateImage from "./pages/UpdateImage";
import SingleProject from "./pages/SingleProject";
import UpdateAbout from "./pages/UpdateAbout";
import UpdateAboutImage from "./pages/UpdateAboutImage";
import Contact from "./pages/Contact";
import Categories from "./pages/Categories";
import AddCategory from "./pages/AddCategory";
import UpdateCategory from "./pages/UpdateCategory";
import SelectCategory from "./pages/SelectCategory";

function App() {
  return (
    <>
      <BrowserRouter>
        <ToastContainer position="top-center" />
        <Routes>
          <Route path="/admin/auth" element={<Login />} />
          <Route path="/home/:category/:categoryId" element={<Home />} />
          <Route path="/" element={<Landing />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />

          <Route path="/users" element={<Users />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/categories/add" element={<AddCategory />} />
          <Route path="/select-category" element={<SelectCategory />} />
          <Route path="/categories/:id" element={<UpdateCategory />} />
          <Route path="/users/:id" element={<EditUser />} />
          <Route
            path="/users/update-password/:id"
            element={<UpdatePassword />}
          />
          <Route path="/users/add" element={<AddUser />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/update-about" element={<UpdateAbout />} />
          <Route path="/about/update-image" element={<UpdateAboutImage />} />
          <Route path="/projects/add" element={<AddProject />} />
          <Route path="/project/:id" element={<SingleProject />} />
          <Route path="/projects/:id" element={<EditProject />} />
          <Route path="/projects/image/:id" element={<UpdateImage />} />
          <Route
            path="/admin/auth/forgot-password"
            element={<ForgotPassword />}
          />
          <Route
            path="/admin/auth/reset/:id/:token"
            element={<ResetPassword />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
