import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import User from "../interface/User";
import axios from "axios";
import { ApiURL } from "../utils/ApiURL";
import { toast } from "react-toastify";
import { handleError } from "../utils/handleError";

const useUser = () => {
  const [userInfo, setUserInfo] = useState<User>();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const history = useNavigate();

  useEffect(() => {
    const token = Cookies.get("access_token")
      ? JSON.parse(Cookies.get("access_token") || "")
      : undefined;
    setUserInfo(token);
  }, [history]);

  const createUser = async ({ name, email, password }: User) => {
    try {
      if (userInfo) {
        const { data } = await axios.post(
          `${ApiURL}/api/auth/create`,
          {
            name,
            email,
            password,
          },
          {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${userInfo?.access_token}`,
            },
          }
        );
        history("/users");
        toast.success(data?.message, { theme: "colored" });
      }
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  const editUser = async ({ name, email }: User, id: string) => {
    try {
      if (userInfo?._id === id) {
        const { data } = await axios.patch(
          `${ApiURL}/api/users/${id}`,
          {
            name,
            email,
          },
          {
            headers: {
              Authorization: `Bearer ${userInfo?.access_token}`,
            },
          }
        );
        toast.success(data.message, { theme: "colored" });
        Cookies.remove("access_token");
        history("/admin/auth");
        setSuccess(true);
      }
      const { data } = await axios.patch(
        `${ApiURL}/api/users/${id}`,
        {
          name,
          email,
        },
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${userInfo?.access_token}`,
          },
        }
      );
      history("/users");
      toast.success(data.message, { theme: "colored" });
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  const getUserById = async (id: string) => {
    try {
      if (userInfo?.access_token) {
        const { data } = await axios.get(`${ApiURL}/api/users/${id}`, {
          headers: {
            Authorization: `Bearer ${userInfo?.access_token}`,
          },
        });

        return data?.user;
      }
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  const getUsers = async () => {
    try {
      if (userInfo?.access_token) {
        const { data } = await axios.get(`${ApiURL}/api/users`, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${userInfo?.access_token}`,
          },
        });
        setLoading(false);
        return data?.users;
      }
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  const login = async ({ email, password }: User) => {
    try {
      const { data } = await axios.post(
        `${ApiURL}/api/auth/signin`,
        {
          email,
          password,
        },
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        }
      );
      Cookies.set("access_token", JSON.stringify(data?.user));
      history("/dashboard");
      toast.success(`Welcome back ${data?.user?.name}`, { theme: "colored" });
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      const { data } = await axios.post(
        `${ApiURL}/api/auth/forgot-password`,
        {
          email,
        },
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        }
      );
      toast.info(data?.message, { theme: "colored" });
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  const deleteUser = async (id: string) => {
    try {
      if (window.confirm("Are you sure want remove this user?")) {
        if (userInfo?._id === id) {
          return toast.error("You cannot remove yoursef.", {
            theme: "colored",
          });
        }
        const { data } = await axios.delete(`${ApiURL}/api/users/${id}`, {
          headers: {
            Authorization: `Bearer ${userInfo?.access_token}`,
          },
        });
        toast.success(handleError(data), { theme: "colored" });
        setSuccess(true);
      }
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  const editPassword = async ({ password }: User, id: string) => {
    if (userInfo?._id === id) {
      const { data } = await axios.patch(
        `${ApiURL}/api/users/update-password/${id}`,
        {
          password,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo?.access_token}`,
          },
        }
      );
      toast.success(data, { theme: "colored" });
      Cookies.remove("access_token");
      history("/admin/auth");
    }
  };

  const resetPassword = async (
    { password }: User,
    id: string,
    token: string
  ) => {
    try {
      const { data } = await axios.patch(
        `${ApiURL}/api/auth/reset/${id}/${token}`,
        {
          password,
        },
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        }
      );
      history("/admin/auth");
      toast.success(data?.message, { theme: "colored" });
    } catch (error) {
      toast.error(handleError(error), { theme: "colored" });
    }
  };

  return {
    userInfo,
    loading,
    success,
    createUser,
    editUser,
    getUserById,
    login,
    forgotPassword,
    getUsers,
    deleteUser,
    editPassword,
    resetPassword
  };
};

export default useUser;
